<template>
    <div 
        class="wrapper"
        :style="image != '' ? `background-image: url('${image}')` : null"
        :class="{
            'full': full,
            'half': half,
            'wrapper--no-overlay': overlay,
            'full--mobile-auto': mobile,
        }"
    >
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        full: {
            type: Boolean,
            default: false,
        },

        half: {
            type: Boolean,
            default: false,
        },

        image: {
            type: String,
            default: false,
        },

        overlay: {
            type: Boolean,
            default: false,
        },

        mobile: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    min-height: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.5);
    display: block;
    width: 100%;
    margin: 0 auto;

    @media(max-width: $mobile-bp) {
        //height: auto;
    }

    &--no-overlay {
        box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.full {
    height: 100vh;

    &--mobile-auto {
        @media(max-width: $mobile-bp) {
            height: auto;
        }
    }
}

.half {
    padding: 50px 0;

    &--mobile-auto {
        @media(max-width: $mobile-bp) {
            height: auto;
        }
    }
}


</style>

